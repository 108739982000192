import Quill from 'quill';

import QuillEmoji from 'quill-emoji';
const { ShortNameEmoji } = QuillEmoji;
const Embed = Quill.import('blots/embed');

const emojiMap = {};

ShortNameEmoji.DEFAULTS.emojiList.forEach((emojiListObject) => {
    emojiMap[emojiListObject.name] = emojiListObject;
});

class EmojiBlot extends Embed {
    static create(value) {
        let node = super.create();
        if (typeof value === 'object') {

            EmojiBlot.buildSpan(value, node);
        } else if (typeof value === "string") {
            const valueObj = emojiMap[value];

            if (valueObj) {
                EmojiBlot.buildSpan(valueObj, node);
            }

        }

        return node;
    }

    static value(node) {
        return node.dataset.name;
    }

    static buildSpan(value, node) {
        node.setAttribute('data-name', value.name);
        node.classList.add('emoji');
        node.classList.add(value.name);
        node.innerText = String.fromCodePoint(...EmojiBlot.parseUnicode(value.unicode));

        // node.classList.add('ap');
        // node.classList.add('ap-' + value.name);
        // emojiSpan.classList.add(this.emojiClass);
        // emojiSpan.classList.add(this.emojiPrefix + value.emoji_order);
        // unicode can be '1f1f5-1f1ea',see emoji-list.js.
        // emojiSpan.innerText = String.fromCodePoint(...EmojiBlot.parseUnicode(value.unicode));
        // node.appendChild(emojiSpan);
    }
    static parseUnicode(string) {
        return string.split('-').map(str => parseInt(str, 16));
    }
}

EmojiBlot.blotName = 'emoji';
EmojiBlot.tagName = 'label';

Quill.register({
    'formats/emoji': EmojiBlot
}, true);